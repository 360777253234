<template>
    <div class="alba" ref="albumList">
        <div class="intro-card artist">
            <RouterLink to="/" class="backBtn">&laquo;</RouterLink>
            <div v-if="artist">
                <h1><span v-html="artist"></span></h1>
                <p><span v-html="description"></span></p>
            </div>
            <div v-else>
                <h1>Wut?</h1>
                <p>Sorry, this artist is not found in my library (yet).</p>
            </div>
        </div>

        <AlbumCard v-for="album in alba" :key="album.id" :album="album" />
    </div>
</template>

<script>
import AlbaService from '@/services/AlbaService.js';
import AlbumCard from '@/components/AlbumCard.vue';

export default {
    components: {
        AlbumCard,
    },
    data() {
        return {
            artist: null,
            description: null,
            alba: [],
        };
    },
    async created() {
        await this.fetchArtistData();
    },
    watch: {
        '$route.params.name': 'fetchArtistData',
    },
    methods: {
        async fetchArtistData() {
            const { name } = this.$route.params;
            const data = await AlbaService.getAlbumsByArtist(name);
            this.artist = data.artist.name;
            this.description = data.artist.description;
            this.alba = data.alba;
        },
    },
};
</script>